import { CommonModel, IModel } from '@/models/Model'
import { IPersonBankAccount, PersonBankAccount } from '@/models/payments/PersonBankAccount'
import { allPaymentMethodTypes, PaymentMethodType } from '@/models/payments/constants'
import { IPersonPaymentService, PersonPaymentService } from '@/models/payments/PersonPaymentService'
import { checkHtml } from '@/utils/html'
import { MainStore } from '@/store/MainStore'
import { paymentsApi } from '@/api/payments-api'

export interface IPersonPaymentMethod extends IModel {
  id: number
  type: PaymentMethodType
  personID: number
  relatedID: number
  isMain: boolean
  isActive: boolean
  isDeleted: boolean
  personBankAccount: IPersonBankAccount | null
  personPaymentService: IPersonPaymentService | null
}

export class PersonPaymentMethod extends CommonModel implements IPersonPaymentMethod {
  id = 0
  type: PaymentMethodType = PaymentMethodType.SEPA
  personID = 0
  relatedID = 0
  isMain = false
  isActive = true
  isDeleted = false

  personBankAccount: PersonBankAccount | null = null
  personPaymentService: PersonPaymentService | null = null

  get isFilled(): boolean {
    if (this.type == PaymentMethodType.SEPA) {
      return Boolean(this.personBankAccount && this.personBankAccount.isFilled)
    } else if (this.type == PaymentMethodType.Card) {
      return Boolean(this.personPaymentService && this.personPaymentService.isFilled)
    }

    return true
  }

  get isMainHtml(): string {
    return checkHtml(this.isMain)
  }

  get isActiveHtml(): string {
    return checkHtml(this.isActive)
  }

  get typeHtml(): string {
    return allPaymentMethodTypes[this.type]
  }

  get relatedShortName(): string {
    if (this.personBankAccount) {
      return this.personBankAccount.shortName
    } else if (this.personPaymentService) {
      return this.personPaymentService.shortName
    }
    return ''
  }

  get notes(): string {
    return this.personBankAccount ? this.personBankAccount.notes : ''
  }

  get shortName(): string {
    return `(${allPaymentMethodTypes[this.type]}): ${this.relatedShortName}`
  }

  get createdAtStr(): string {
    return this.personBankAccount ? this.personBankAccount.createdAtStr : ''
  }

  get whoCreatedName(): string {
    return this.personBankAccount ? this.personBankAccount.whoCreatedName : ''
  }

  titleRelated(last = 0): string {
    if (this.personBankAccount) {
      return this.personBankAccount.titleHtml(last)
    } else if (this.personPaymentService) {
      return this.personPaymentService.titleHtml
    }
    return ''
  }

  titleHtml(last = 0): string {
    const s = `(<i>Type:</i> <b>${this.typeHtml}</b>): ${this.titleRelated(last)}`

    return this.isActive ? s : `<s>${s}</s>`
  }

  constructor(src: IPersonPaymentMethod | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IPersonPaymentMethod) {
    Object.assign(this, src)
    this.personBankAccount = src.personBankAccount
      ? new PersonBankAccount(src.personBankAccount)
      : null
    this.personPaymentService = src.personPaymentService
      ? new PersonPaymentService(src.personPaymentService)
      : null

    this.initModel(src)
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await paymentsApi.personPaymentMethod.add(this.forStorage().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await paymentsApi.personPaymentMethod.update(this.forStorage().toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async delete(): Promise<boolean> {
    if (!confirm(`Are you sure you want to delete method?`)) {
      return false
    }

    try {
      await paymentsApi.personPaymentMethod.delete(this.id)
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  dataIsSame(value: PersonPaymentMethod): boolean {
    if (this.type == value.type) {
      if (this.personPaymentService && value.personPaymentService) {
        return this.personPaymentService.dataIsSame(value.personPaymentService)
      }

      if (this.personBankAccount && value.personBankAccount) {
        return this.personBankAccount.dataIsSame(value.personBankAccount)
      }

      return true
    }

    return false
  }

  forStorage(): PersonPaymentMethod {
    if (this.personBankAccount) {
      this.personBankAccount.personID = this.personID
    }
    if (this.personPaymentService) {
      this.personPaymentService.personID = this.personID
    }
    return this
  }

  toInterface(): IPersonPaymentMethod {
    return {
      id: this.id,
      type: this.type,
      personID: this.personID,
      relatedID: this.relatedID,
      isMain: this.isMain,
      isActive: this.isActive,
      isDeleted: this.isDeleted,
      personBankAccount: this.personBankAccount ? this.personBankAccount.toInterface() : null,
      personPaymentService: this.personPaymentService
        ? this.personPaymentService.toInterface()
        : null,
    }
  }
}
