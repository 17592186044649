import { CommonModel, IModel } from '@/models/Model'
import { paymentsApi } from '@/api/payments-api'
import { MainStore } from '@/store/MainStore'
import { shortenString } from '@/utils/strings'
import { PersonSimple } from '@/models/persons/PersonSimple'
import { formatDateTime } from '@/utils'

export interface IPersonBankAccount extends IModel {
  id: number
  personID: number
  iban: string
  bicSwift: string
  accountHolder: string
  notes: string
  deactivationDate: Date | null
  createdAt: Date | null
  createdBy: number
}

export class PersonBankAccount extends CommonModel implements IPersonBankAccount {
  id = 0
  personID = 0
  iban = ''
  bicSwift = ''
  accountHolder = ''
  notes = ''
  deactivationDate: Date | null = null
  createdAt: Date | null = new Date()
  createdBy = 0

  // after load
  whoCreated: PersonSimple | null = null

  constructor(src: IPersonBankAccount | PersonBankAccount | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IPersonBankAccount | PersonBankAccount) {
    Object.assign(this, src)
    this.deactivationDate = this.dateOrNull(src.deactivationDate)
    this.createdAt = this.dateOrNull(src.createdAt)

    this.initModel(src)
  }

  get isFilled(): boolean {
    return Boolean(this.iban || this.bicSwift || this.accountHolder || this.notes)
  }

  get shortName(): string {
    return `IBAN: ${this.iban}`
  }

  get createdAtStr(): string {
    return formatDateTime(this.createdAt)
  }

  get whoCreatedName(): string {
    return this.whoCreated ? this.whoCreated.fullName : ''
  }

  titleHtml(last = 0): string {
    let res = `<i>IBAN:</i> <b>${shortenString(this.iban, last)}</b>`
    this.bicSwift && (res += `, <i>BIC/SWIFT:</i> <b>${shortenString(this.bicSwift, last)}</b>`)
    this.accountHolder && (res += `, <i>Holder:</i> <b>${this.accountHolder}</b>`)

    return res
  }

  dataIsSame(value: PersonBankAccount): boolean {
    return this.iban == value.iban
  }

  async save(): Promise<boolean> {
    try {
      return this.id ? await this.update() : await this.add()
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async add(): Promise<boolean> {
    try {
      this.init(await paymentsApi.personBankAccount.add(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  async update(): Promise<boolean> {
    try {
      this.init(await paymentsApi.personBankAccount.update(this.toInterface()))
      return true
    } catch (e) {
      await MainStore.addError(e as Error)
      return false
    }
  }

  toInterface(): IPersonBankAccount {
    return {
      id: this.id,
      personID: this.personID,
      iban: this.iban,
      bicSwift: this.bicSwift,
      accountHolder: this.accountHolder,
      notes: this.notes,
      deactivationDate: this.deactivationDate,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
    }
  }
}
