import { CommonModel, IModel } from '@/models/Model'

export interface IPersonPaymentService extends IModel {
  id: number
  personID: number
  name: string
}

export class PersonPaymentService extends CommonModel implements IPersonPaymentService {
  id = 0
  personID = 0
  name = ''

  get isFilled(): boolean {
    return Boolean(this.name)
  }

  get titleHtml(): string {
    return `<i>Name:</i> <b>${this.name}</b>`
  }

  get shortName(): string {
    return this.name
  }

  constructor(src: IPersonPaymentService | null = null) {
    super()

    if (src) {
      this.init(src)
    }
  }

  private init(src: IPersonPaymentService) {
    Object.assign(this, src)

    this.initModel(src)
  }

  dataIsSame(value: PersonPaymentService): boolean {
    return this.name == value.name
  }

  toInterface(): IPersonPaymentService {
    return {
      id: this.id,
      personID: this.personID,
      name: this.name,
    }
  }
}
